import React from 'react'

export default (props: Object) => {
  return (
    <svg
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m18.52 0c.8155556 0 1.4794444.64555556 1.4794444 1.44222222v17.11444448c0 .7966666-.6638888 1.4433333-1.4794444 1.4433333h-17.045c-.81388889 0-1.475-.6466667-1.475-1.4433333v-17.11444448c0-.79666666.66111111-1.44222222 1.475-1.44222222zm-12.58722222 7.49777778h-2.96944445v9.54444442h2.96944445zm7.55111112-.23666667c-1.4427778 0-2.4105556.79111111-2.8061111 1.54166667h-.0411111v-1.305h-2.84333337v9.54444442h2.96277777v-4.7205555c0-1.2455556.2355556-2.4516667 1.7794445-2.4516667 1.5222222 0 1.5411111 1.4238889 1.5411111 2.5305556v4.6416666h2.9661111v-5.2344444c0-2.57055558-.555-4.54666669-3.5588889-4.54666669zm-9.03500001-4.50777778c-.95277778 0-1.72111111.77055556-1.72111111 1.72 0 .95.76833333 1.72055556 1.72111111 1.72055556.94888889 0 1.71888889-.77055556 1.71888889-1.72055556 0-.94944444-.77-1.72-1.71888889-1.72z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}
