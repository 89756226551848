import React from 'react'

export default (props: Object) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="19"
      viewBox="0 0 24 19"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M7.548 19c9.057 0 14.01-7.31 14.01-13.647 0-.208-.005-.414-.014-.62A9.873 9.873 0 0 0 24 2.249c-.882.382-1.832.64-2.828.755A4.838 4.838 0 0 0 23.338.351c-.952.55-2.005.949-3.127 1.164A4.98 4.98 0 0 0 16.617 0c-2.72 0-4.925 2.148-4.925 4.796 0 .376.044.742.128 1.094C7.728 5.689 4.099 3.78 1.67.878a4.69 4.69 0 0 0-.666 2.41c0 1.665.869 3.134 2.19 3.994a4.987 4.987 0 0 1-2.23-.6v.06c0 2.324 1.697 4.263 3.95 4.703a5.053 5.053 0 0 1-2.224.082c.627 1.906 2.445 3.293 4.6 3.332a10.046 10.046 0 0 1-6.115 2.053c-.397 0-.79-.022-1.175-.067A14.209 14.209 0 0 0 7.548 19"
      />
    </svg>
  )
}
