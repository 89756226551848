import React from 'react'

export default (props: Object) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="19"
      viewBox="0 0 26 19"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M13.04-.001c.613 0 8.174.023 10.119.567 1.118.312 2 1.232 2.298 2.4.483 1.882.537 5.58.543 6.38v.308c-.006.799-.06 4.498-.543 6.38-.299 1.167-1.18 2.087-2.298 2.4-1.863.52-8.873.563-10.005.566h-.31c-1.131-.003-8.141-.046-10.003-.567-1.119-.312-2-1.232-2.299-2.4-.498-1.94-.54-5.813-.543-6.445v-.176c.003-.632.045-4.505.543-6.446.299-1.168 1.18-2.088 2.299-2.4C4.786.022 12.346 0 12.96 0zM10.4 5.428v8.144L17.154 9.5l-6.756-4.072z"
      />
    </svg>
  )
}
