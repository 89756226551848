import React from 'react'

type Props = {
  className?: string
}

const Logo: React.FC<Props> = ({ className }) => {
  return (
    <svg
      className={className}
      width="140"
      height="25"
      viewBox="0 0 140 25"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Flamelink Logo</title>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-165.000000, -47.000000)" fill="currentColor">
          <g transform="translate(165.000000, 47.000000)">
            <path
              d="M14.3405097,0.073174313 C14.1525832,-0.0739950603 13.9993025,0.00793045986 14,0.255189835 L14.0332482,16.9379689 C14.0339481,17.1852283 14.1879288,17.5081112 14.3758552,17.6552806 L23.6594903,24.9270046 C23.8474168,25.0738033 24.0006975,24.9922485 24,24.7446184 L23.9664018,8.06220999 C23.9660519,7.81457991 23.8120712,7.49169698 23.6241448,7.3445276 L14.3405097,0.073174313 Z"
              fillOpacity="0.8"
            ></path>
            <path
              d="M7.34050966,0.073174313 C7.15258324,-0.0739950603 6.99930248,0.00793045986 7,0.255189835 L7.03324822,16.9379689 C7.03394813,17.1852283 7.18792881,17.5081112 7.37585523,17.6552806 L16.6594903,24.9270046 C16.8474168,25.0738033 17.0006975,24.9922485 17,24.7446184 L16.9664018,8.06220999 C16.9660519,7.81457991 16.8120712,7.49169698 16.6241448,7.3445276 L7.34050966,0.073174313 Z"
              fillOpacity="0.6"
            ></path>
            <path
              d="M0.340509661,0.073174313 C0.152583242,-0.0739950603 -0.00069752471,0.00793045986 0,0.255189835 L0.033598172,16.9379689 C0.033948128,17.1852283 0.187928807,17.5081112 0.375855226,17.6552806 L9.65949034,24.9270046 C9.84741676,25.0738033 10.0006975,24.9922485 10,24.7446184 L9.96675178,8.06220999 C9.96605187,7.81457991 9.81207119,7.49169698 9.62414477,7.3445276 L0.340509661,0.073174313 Z"
              fillOpacity="0.4"
            ></path>
            <polygon points="42 8 41.9785743 10.8411928 34.0528102 10.8441401 34.054538 15.4327106 41.2736012 15.4297633 41.2746379 18.2705877 34.0555748 18.273535 34.0576482 24.9988948 31.0058748 25 31 8.00442094"></polygon>
            <polygon points="45.99371 7 46 24.9988958 43.0059406 25 43 7.00110425"></polygon>
            <path d="M56.8265143,20.4275115 L56.8261412,19.284841 L53.506778,19.2859248 C51.8103473,19.2866473 51.0235049,19.7389468 51.0238779,20.857774 C51.0242511,21.9289146 51.9095887,22.6192856 53.3605276,22.6185636 C55.204328,22.6178405 56.6548938,21.6655549 56.8265143,20.4275115 L56.8265143,20.4275115 Z M56.8526304,24.8558561 L56.8518842,23.3085727 C55.9430421,24.4273998 54.4678525,24.999277 52.5990552,25 C49.771795,25.0010833 48.0007467,23.3111015 48,21.0496039 C47.9988813,18.692372 49.793061,17.2397391 52.9896782,17.2144508 L56.825395,17.2137283 L56.8250219,16.8802838 C56.8246488,15.404169 55.8161922,14.5234132 53.8492727,14.5241357 C52.6199481,14.524497 51.2921282,14.9294712 49.9400576,15.7155764 L48.7834851,13.5732952 C50.6765332,12.5491186 52.1517228,12.0007234 54.5614976,12 C58.0032335,11.9985559 59.9466484,13.6888989 59.9723915,16.5215511 L60,24.8547723 L56.8526304,24.8558561 Z"></path>
            <path d="M83.9971555,16.9551458 L84,24.9923323 L80.9304109,24.9934277 L80.927922,17.8708856 C80.9272108,16.0419619 79.8722507,14.9356256 78.1850968,14.9363558 C76.2166912,15.0090162 75.0223498,16.5494891 75.0230609,18.6427651 L75.0251943,24.9956185 L71.9556052,24.9963487 L71.9531163,17.8741717 C71.9524051,16.0452481 70.9212678,14.9389117 69.2341139,14.9392769 C67.2422411,15.0123024 66.0475441,16.5527753 66.0486108,18.6464164 L66.0507441,24.9989046 L63.0042668,25 L63,12.1263346 L66.0461218,12.1252392 L66.0468329,14.5076961 C66.9136994,12.7985343 68.4596942,12.0281153 70.4512115,12.0029215 C72.6542896,12.0025564 74.1775283,13.132626 74.7638543,15.0816766 C75.5834307,12.9880355 77.2232946,12.0248291 79.4263727,12 C82.2612892,11.9989051 83.9960888,13.8993937 83.9971555,16.9551458"></path>
            <path d="M95.294104,17.4948119 C95.2256537,15.6070018 94.0238181,14.4128876 92.1873554,14.4136125 C90.4420452,14.4143381 89.2185394,15.6095402 88.9468021,17.4973503 L95.294104,17.4948119 Z M97.9698566,19.572636 L89.0155963,19.5762622 C89.4015321,21.3926358 90.7168782,22.4913804 92.4848906,22.4906552 C93.7317865,22.4902926 94.88753,21.9880611 95.748834,21.0557599 L97.3362615,22.7996091 C96.1351138,24.185913 94.3674454,24.9989115 92.2361994,25 C88.4734976,25.0014499 86.001376,22.3735283 86,18.5261089 C85.9993122,14.6308234 88.5367883,12.0014513 92.1639654,12 C96.4257695,11.9981877 98.2629201,14.9368764 97.9698566,19.572636 L97.9698566,19.572636 Z"></path>
            <polygon points="102.99371 7 103 24.9988958 100.005941 25 100 7.00110425"></polygon>
            <path d="M108.893984,12.3283422 L108.898211,24.9989219 L106.11187,25 L106.107968,12.3294203 L108.893984,12.3283422 Z M109,8.70483961 C109.000325,9.69956887 108.379187,10.4100384 107.500488,10.4103979 C106.621789,10.4107572 106.000325,9.70064698 106,8.70591771 C105.999675,7.71082907 106.620813,7.0003595 107.499187,7 C108.377886,6.99964077 108.999675,7.70975097 109,8.70483961 L109,8.70483961 Z"></path>
            <path d="M124.9972,16.9563987 L125,24.9956174 L121.977773,24.996713 L121.975322,17.87237 C121.974622,16.0667232 120.93641,14.9601071 119.229275,14.9608376 C117.199405,14.9856725 116.000175,16.5506394 116.001225,18.6685492 L116.003325,24.9989043 L113.0042,25 L113,12.1234448 L115.998775,12.1219839 L115.999475,14.5287825 C116.87562,12.8195538 118.421037,12.0248354 120.427805,12 C123.265562,11.9989048 124.99615,13.899874 124.9972,16.9563987"></path>
            <polygon points="132.970763 19.3220661 131.050564 21.4086866 131.051631 24.9988958 128.006043 25 128 7.00110423 131.045232 7 131.049142 17.4305257 136.200835 12.0433716 139.71492 12.0422673 135.07829 17.0411018 140 24.9955831 136.369324 24.9966873"></polygon>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default Logo
