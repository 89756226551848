import React from 'react'

export default (props: Object) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="22"
      viewBox="0 0 23 22"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path
          fill="currentColor"
          d="M11.353 0C5.083 0 0 5.014 0 11.2c0 4.947 3.252 9.145 7.765 10.626.568.102.775-.243.775-.54 0-.266-.01-.97-.016-1.905-3.158.677-3.824-1.5-3.824-1.5-.516-1.295-1.26-1.64-1.26-1.64-1.032-.694.077-.68.077-.68 1.14.08 1.74 1.154 1.74 1.154 1.012 1.712 2.656 1.217 3.304.93.103-.723.395-1.217.72-1.497-2.52-.282-5.171-1.243-5.171-5.535 0-1.222.442-2.222 1.169-3.005-.118-.283-.507-1.422.11-2.963 0 0 .954-.302 3.123 1.148a11.014 11.014 0 0 1 2.842-.377 11.03 11.03 0 0 1 2.842.377c2.168-1.45 3.12-1.148 3.12-1.148.619 1.541.23 2.68.112 2.963.728.783 1.168 1.783 1.168 3.005 0 4.303-2.655 5.25-5.184 5.527.407.345.77 1.03.77 2.074 0 1.497-.014 2.705-.014 3.072 0 .3.205.648.781.538 4.507-1.483 7.758-5.678 7.758-10.624 0-6.186-5.084-11.2-11.354-11.2"
        />
      </g>
    </svg>
  )
}
