module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-MM4Z99Q","includeInDevelopment":false,"routeChangeEventName":"e_routeChange","defaultDataLayer":{"type":"object","value":{"environmentName":"production","pageType":"page","siteName":"www"}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-102176977-1","head":true,"respectDNT":true,"pageTransitionDelay":0,"sampleRate":100,"siteSpeedSampleRate":10,"forceSSL":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#ff6633","showSpinner":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Flamelink.io","short_name":"flamelink.io","start_url":"/","background_color":"#f5f5f5","theme_color":"#ff6633","display":"minimal-ui","icon":"src/images/flamelink-icon-1024x1024.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"0":{"precachePages":["/features","/business","/content","/tech","/pricing","/case-studies","/case-studies/*","/slack","/security"],"appendScript":"/home/runner/work/flamelink-website/flamelink-website/src/custom-sw-code.js"},"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
